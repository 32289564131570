import Model from "../../../model/Model";
import Field from "../../../model/Field";
import SpanColumn from "../../../model/SpanColumn";
import ClickColumn from "../../../model/ClickColumn";

export default class RecordModel extends Model{

    getData(t){
        let params = typeof t == 'undefined' ? {} : t
        return this.getRequest('/resume/analysis-record/getResumeImportRecord',params)
    }

    remove(ids){
        let params = {ids:ids}
        return this.postRequest('/resume/resume/to-complete/delete',params)
    }

    initFields() {
        return [
            new Field("createdTime","操作时间")
                .column(new SpanColumn().width(250)),
            new Field("createdUserName","操作人"),
            new Field("typeName","类型"),
            new Field("resumeNum","全部人才")
                .column(new SpanColumn().beforeRender(value=>{
                return value == 0?'-':value
            }).align('center')),

            new Field("enterNum","已录入系统")
                .column(
                    new ClickColumn((value,row,vm)=>{
                    if(value == 1){
                        vm.$emit("showDetail",row.resumeId)
                    }
                    if(value >1){
                        vm.$emit("recordList",row.id)
                    }
                }).beforeRender((value)=>{
                    return value == 0?'-':value
                }).align('center')),

            new Field("analysisFailNum","解析失败")
                .column(new SpanColumn().beforeRender(value=>{
                    return value == 0?'-':value
                })),

            new Field("handle","操作")
                .column(new ClickColumn((value,row,vm)=>{
                    if(row.needUpdateNum>0){
                        vm.$emit("handler",row.id)
                    }
                    })
                    .beforeRender((value,row)=>{
                        if(row.needUpdateNum>0){
                            return '处理'
                        }else{
                            return '-'
                        }

                    })
                )
        ]
    }

}
