<template>
  <aside class="menu-expanded">
    <div class="menu">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="" name="1">
          <template slot="title">
            <div class="subTit">
              <el-icon class="el-icon-files"> </el-icon>快捷菜单
            </div>
          </template>
          <ul class="menuList">
            <li
              @click="searchCus(item.val, 'quick')"
              v-for="item in menuList"
              :class="current == item.val ? 'actived' : ''"
              :key="item.val"
            >
              {{ item.name }}
              <span
                v-for="i in dataCount"
                :key="i.type"
                style="float: right; margin-right: 15px"
              >
                <span v-if="item.name == i.type">{{ i.numberText }}</span>
              </span>
            </li>
          </ul>
        </el-collapse-item>
        <el-collapse-item title="" name="2">
          <template slot="title"
            ><div class="subTit">
              <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
            </div></template
          >
          <ul class="menuList">
            <li
              @click="searchCus(item.id, 'order')"
              v-for="item in OrderSearchList"
              :class="current == item.id ? 'actived' : ''"
              :key="item.id"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                :title="item.name"
              >
                {{ item.name }}</span
              >

              <span class="hits" style="display: block; margin-right: 15px">{{
                item.hits
              }}</span>
              <div class="closed" @click.stop="delSearch(item.id)">&times;</div>
            </li>
          </ul>
        </el-collapse-item>
        <el-collapse-item title="" name="3">
          <template slot="title"
            ><div class="subTit">
              <el-icon class="el-icon-folder-opened"></el-icon>文件夹
              <div class="edit" @click.stop="editFavorite">编辑</div>
            </div></template
          >
          <el-collapse v-model="activeNames1">
            <el-collapse-item
              name="1"
              v-if="myFileData && myFileData.length > 0"
            >
              <template slot="title"
                ><div class="subTit_wj">
                  <i class="iconfont icon-lnav_wdwj"></i>我的文件夹
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'folder')"
                  v-for="item in myFileData"
                  :class="current == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <span style="float: right; margin-right: 15px">
                    <span>{{ item.counts }}</span>
                  </span>
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item name="2" v-if="shareData && shareData.length > 0">
              <template slot="title"
                ><div class="subTit_wj">
                  <i class="iconfont icon-rnav_gxwj"></i>共享文件夹
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'folder')"
                  v-for="item in shareData"
                  :class="current == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <span style="float: right; margin-right: 15px">
                    <span>{{ item.counts }}</span>
                  </span>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
    </div>
  </aside>
</template>

<script>
import {
  GetSearchPage,
  deleteSearchById,
  getListFavorite,
  resumeDataCount,
} from "../../api/api";
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    actived: {
      type: String,
      default: "我入库的人才",
    },
  },
  data() {
    return {
      current: "我入库的人才",
      activeNames: "1",
      activeNames1: ["1", "2"],
      menuList1: JSON.parse(sessionStorage.getItem("permiss")),
      menuList: [
        {
          name: "我入库的人才",
          val: "我入库的人才",
        },
        {
          name: "我备注的人才",
          val: "我备注的人才",
        },
        {
          name: "所有人才",
          val: "所有人才",
        },
        {
          name: "我的最近浏览",
          val: "我的最近浏览",
        },
        {
          name: "导入记录",
          val: "导入记录",
        },
        {
          name: "待处理人才",
          val: "待处理人才",
        },
      ],
      OrderSearchList: [],
      myFileData: [],
      shareData: [],
      type: "人才",
      dataCount: [],
    };
  },
  created() {
    this.loadOrderSearchList();
    this.getListFavorite();
    this.current = this.actived;
  },
  methods: {
    searchCus(a, b) {
      switch (b) {
        case "quick": //快捷菜单
          if (a == "导入记录") {
            this.$router.push({ path: "/resume/importRecord" });
            return;
          } else if (a == "待处理人才") {
            this.$router.push({ path: "/resume/pending" });
            return;
          } else {
            this.$router.push({ path: "/resume?status=" + a });
          }
          return;
        case "order":
          this.$router.push({ path: "/resume?order=" + a });
          return;
        case "folder":
          this.$router.push({ path: "/resume?folder=" + a });
          return;
        default:
          if (this.current == a) {
            return;
          }
          this.current = a;
          this.$router.push({ path: "/resume" });
          return;
      }
    },
    editFavorite() {
      //编辑收藏夹
      this.fileManagement = true;
      this.isCheckbox.edit = false;
    },
    loadOrderSearchList() {
      GetSearchPage({ type: "人才" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.current == id) {
                this.reset();
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getListFavorite() {
      getListFavorite({ type: this.type }).then((res) => {
        this.shareData = res.result.shareList;
        this.myFileData = res.result.myList;
      });
      this.getResumeDataCount();
    },
    //快捷菜单人才数量
    getResumeDataCount() {
      resumeDataCount().then((res) => {
        this.dataCount = res.result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
</style>
