import {
    getRequest,
    postRequestUseBodyParam,
} from '../api/http';

export default class Model {

    _fields = []
    vm = null
    constructor(){
        this._fields = this.initFields()
    }

    initFields(){

    }

    getRequest(uri,params){
       return  getRequest(uri,params)
    }

    postRequest(uri,params){
        return postRequestUseBodyParam(uri,params)
    }

    getFields(k){
        if(typeof k=='undefined'){
            return this._fields
        }
        let keys = k.split(',')
        let res = []
        for(let key in keys){
            for(let i=0;i<this._fields.length;i++){
                if(this._fields[i].key == key){
                    res.push(this._fields[i])
                }
            }
        }
        return  res
    }

}
