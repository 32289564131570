import fa from "element-ui/src/locale/lang/fa";

export default class Column {
    static SPAN = 1;
    static CLICK = 2;
    _clickCb = null;
    _width = ""
    _fixed = false
    _before_render = null
    _align="left"

    constructor(type){
        this.type = type
    }

    width(w){
        if (typeof w=='undefined'){
            return this._width
        }
        this._width = w
        return this
    }

    fixed(f){
        if(typeof f == "undefined"){
            return this._fixed
        }
        this._fixed = f
        return this
    }

    align(a){
        if(typeof a == "undefined"){
            return this._align
        }
        this._align = a
        return this
    }

    click(c){
      if(typeof c =="undefined"){
          return this._clickCb
      }
      this._clickCb = c
        return this
    }

    beforeRender(b){
        if(typeof b =="undefined"){
            return this._before_render
        }
        this._before_render = b
        return this
    }
}
