import SpanColumn from "./SpanColumn";

export default class Field {

     _column = null
     constructor (key,name){
         this.key = key;
         this.name = name;
     }

     column(c){
         if(typeof c =="undefined"){
             let column = this._column == null ? new SpanColumn():this._column
             return column
         }
         this._column = c
         return this
     }

 }
