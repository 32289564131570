<template>
  <el-pagination
    v-if="page.total > 0"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="page.current"
    :page-size="page.size"
    :pager-count="5"
    layout="total,prev, pager, next,sizes"
    :total="page.total"
    :page-sizes="[10, 20, 50, 100]"
  ></el-pagination>
</template>

<script>
export default {
  name: "SimplePagination",
  props: {
    page: {
      type: Object,
      default: function () {
        return {
          current: 1,
          total: 0,
          size: 50,
        };
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("input", {
        current: 1,
        total: this.page.total,
        size: val,
      });
    },
    handleCurrentChange(val) {
      this.$emit("input", {
        current: val,
        total: this.page.total,
        size: this.page.size,
      });
    },
  },
};
</script>
