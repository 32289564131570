<template>
  <div>
    <el-table-column
      :prop="field.key"
      :width="field.column().width()"
      :fixed="field.column().fixed()"
      :align="field.column().align()"
      :label="field.name"
    >
      <template v-if="field.column().beforeRender != null" slot-scope="scope">
        <el-button
          @click="doClick(scope)"
          class="tabHref"
          v-if="field.column().type == 2 && getRendText(scope)"
          type="text"
          size="small"
          style="color: #526ecc"
          >{{ getRendText(scope) }}</el-button
        >
        <span @click="doClick(scope)" v-else>{{ getRendText(scope) }}</span>
      </template>
    </el-table-column>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: {},
    },
  },
  methods: {
    getRendText(scope) {
      let beforeRender = this.field.column().beforeRender();
      if (beforeRender == null) {
        return scope.row[this.field.key] || "-";
      } else {
        return beforeRender(scope.row[this.field.key], scope.row) || "-";
      }
    },
    doClick(scope) {
      let click = this.field.column().click();
      if (click != null) {
        click(scope.row[this.field.key], scope.row, this);
      }
    },
  },
};
</script>
