<template>
  <div class="wrapper resumeImportRecord">
    <el-row class="main">
      <!--导航菜单-->
      <resumeMenu :actived="actived" ref="menu"></resumeMenu>
      <section
        class="content-container clearfix"
        :class="collapsed ? 'screen' : ''"
      ></section>
    </el-row>

    <div style="width: 100%">
      <div
        style="
          width: calc(100% - 275px);
          background-color: white;
          margin-right: 10px;
          padding: 20px 0px;
          margin-left: 260px;
          border: 1px #e5e5e5 solid;
        "
      >
        <div style="width: 100%">
          <div
            style="float: left; margin-bottom: 10px"
            v-if="checkedIds.length > 0"
          >
            <el-button
              type="default"
              plain
              size="mini"
              style="margin: 10px 15px 0px 15px"
              @click="delAll"
            >
              <i class="el-icon-delete"></i> 批量删除
            </el-button>
          </div>
          <p
            style="float: left; margin: 0 10px 0; font-size: 13px; color: #999"
          >
            默认显示近<span style="color: #606266; margin: 0 5px">30</span
            >天的导入记录
          </p>
          <div style="float: right; padding-bottom: 10px">
            <simple-pagination :page="page" v-model="page"></simple-pagination>
          </div>
        </div>

        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
            height: '35px',
          }"
          class="tabBorder custor"
          v-loading="listLoading"
          ref="tableList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :data="list"
        >
          <div v-for="(field, index) in fields" :key="index">
            <simple-table-column
              @recordList="recordList"
              @handler="handler"
              @showDetail="showDetail"
              :field="field"
            ></simple-table-column>
          </div>
        </el-table>

        <div style="width: 100%; display: block; height: 40px">
          <div style="float: right; padding-top: 5px">
            <simple-pagination :page="page" v-model="page"></simple-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>人才
      </div>

      <resumeDetail
        v-if="isShowDetail"
        :resumeDetailId="resumeId"
        :repeatResume="repeatResume"
        :hover="hoverInfo"
      ></resumeDetail>
    </el-drawer>
  </div>
</template>
<script>
import resumeMenu from "./resumeMenu";
import RecordModel from "./model/RecordModel";
import SimpleTableColumn from "../../components/common/SimpleTableColumn";
import SimplePagination from "../../components/common/SimplePagination";
import resumeDetail from "../../components/resumeDetail";

let model = new RecordModel();
export default {
  components: {
    SimplePagination,
    SimpleTableColumn,
    resumeMenu,
    resumeDetail,
  },
  data() {
    return {
      listLoading: true,
      collapsed: "upDateCollapsed",
      actived: "导入记录",
      model: model,
      fields: model.getFields(),
      list: [],
      page: {
        current: 1,
        total: 0,
        size: 50,
      },
      isShowDetail: false,
      resumeId: 0,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      checkedIds: [],
    };
  },
  name: "record",
  watch: {
    "$route.query": {
      handler() {
        this.getData(this.getFilterKey());
      },
    },
    page(v, ov) {
      this.getData(this.getFilterKey());
    },
    isShowDetail(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  methods: {
    getFilterKey() {
      return {
        ...this.page,
      };
    },
    showDetail(v) {
      this.isShowDetail = true;
      this.resumeId = v;
    },
    handler(v) {
      this.$router.push({
        path: "/resume/pending?recordId=" + v,
      });
    },
    recordList(v) {
      this.$router.push({
        path: "/resume?recordId=" + v,
      });
    },
    getData() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      model.getData(this.getFilterKey()).then((res) => {
        this.listLoading = false;
        if (res.success) {
          this.list = res.result.records;
          this.page.size = res.result.size;
          this.page.total = res.result.total;
        }
      });
    },
    handleSelectionChange(val) {
      this.checkedIds = [];
      for (let ind in val) {
        this.checkedIds.push(this.list[ind].id);
      }
    },
    delAll() {
      this.$confirm("是否删除选中数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          model.remove(this.checkedIds).then((res) => {
            if (res.success) {
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getData(this.getFilterKey());
  },
};
</script>

<style lang='scss'>
.resumeImportRecord {
  .el-table .cell {
    padding-left: 20px;
  }
}
</style>
